.tagline-toolbar {
  margin: 20px 0px 40px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  flex-direction: row;
}

.tagline-toolbar-btn {
  width: 300px;
  @media only screen and (max-width: 600px) {
    width: 150px;
    height: 100px;
  }
}

.home-subtitle {
  padding: 0px 0px 40px 0px;
}

.button:hover {
  color: black;
  background-color: rgb(239, 239, 239);
  transition: all 0.3s;
}

@media (prefers-color-scheme: dark) {
  .button:hover {
    color: white;
    font-weight: bold;
    background-color: rgb(82, 82, 82);
  }

  .social-button:hover {
    color: white;
  }
}
